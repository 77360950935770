<template>
  <v-alert text color="info">
    <v-row align="center" no-gutters>
      <v-col cols="12" xs="10" sm="10" md="10" lg="10">{{ alertmsg }}</v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-btn cols="12" xs="1" sm="1" md="1" lg="1" color="info" outlined @click="setflasesnackbar"> Okay </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "Alertmsg",

  props: {
    alertmsg: {
      type: String,
    },
  },

  created() {
    console.log("Created-Alertmsg", this.alertmsg)
  },
  methods: {
    setflasesnackbar() {
      this.$emit("setflasealert")
    },
  },
}
</script>
