/* Since we have 2 forms one for Mobile View and one for Web View
all the common functions have been abstracted to this common mixin
it relates to RequestForm.vue and RequestFormSm.Vue */
/*import { mapState, mapActions } from "vuex"
import axios from "axios"
import { Timestamp } from "@/firebase"*/

export const formMixin = {
  props: {
    request: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data: function () {
    return {
      //Initials the field labels

      picker: new Date().toISOString().substr(0, 10),
      requiredRules: [(v) => !!v || "This field is required"],
    }
  },

  methods: {
    async deleteFromFb(msg) {
      if (
        await this.$root.$confirm("Delete", msg, {
          color: "red",
        })
      ) {
        return true
      } else {
        return false
      }
    },

    /*async genralmsg(msg) {
      if (
        await this.$root.alert(msg, {
          color: "green",
        })
      ) {
       return true
      } else {
        return false
      }
    },*/
  },
}
