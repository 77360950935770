<template>
  <div>
    <v-snackbar v-model="snackbar">
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="setflasesnackbar"> Okay </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Snackbar",
  props: {
    snackbartext: {
      type: String,
    },
    snackbar: {
      type: Boolean,
    },
  },

  created() {
    console.log("Created-Snackbar", this.snackbartext)
  },
  methods: {
    setflasesnackbar() {
      // this.snackbar=false;
      this.$emit("setflasesnackbar")
    },
  },
}
</script>
