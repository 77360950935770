<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>

    <v-data-table
      :headers="headers"
      :items="configs"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      :show-expand="expandflag"
      class="elevation-1"
      :loading="notloaded"
      loading-text="Loading..."
      :search="search"
      :footer-props="{
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Configuration </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD Config</v-btn>
            </template>-->
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                        <div v-if="genedit">
                          <h4>Descriptions</h4>
                          {{ editedItem.description }}
                        </div>
                        <v-select
                          v-else
                          v-model="editedItem.description"
                          label="Descriptions"
                          :items="descriptions"
                          :rules="inputrequired"
                          @change="descriptionslected()"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" xs="4" sm="4" md="4" lg="4">
                        <div>
                          <h4>Comments</h4>
                          {{ editedItem.comments }}
                        </div>
                      </v-col>

                      <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-text-field
                          v-if="confignumbertype"
                          v-model="editedItem.value"
                          label="Value"
                          :rules="decimalinput"
                          required
                        ></v-text-field>

                        <v-select
                          v-else
                          v-model="editedItem.value"
                          label="Option"
                          :items="YesNo"
                          :rules="inputrequired"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" text :disabled="!valid" @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>-->
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Config</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { configallRef, usersRef } from "@/fb"
//,usersRef
import { formMixin } from "@/mixins/formMixin.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
export default {
  name: "userconfigmgt",
  //props: ["roles"],
  mixins: [formMixin],
  components: { Snackbar, Alertmsg, Spinner },
  data: () => ({
    dialog: false,
    menuholiday: false,
    // menugml: false,
    search: "",
    domain: "",
    lazy: false,
    valid: true,
    notloaded: true,
    singleExpand: false,
    expanded: [],
    expandflag: false,
    configmenus: ["General"],
    headers: [],

    headersgen: [
      {
        text: "Description",
        align: "start",
        sortable: true,
        value: "description",
      },
      { text: "Value", value: "value" },

      { text: "Actions", value: "actions", sortable: false },
    ],

    inputrequired: [(v) => !!v || "Input is required"],
    decimalinput: [(v) => !!v || "Input is required", (v) => /^[0-9,.,,\s]+$/.test(v) || "Only Numbers and . allowed"],
    configs: [],
    configgen: [],

    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    configall: [],
    comments: [],
    descriptions: [],
    configcomments: "",

    // descriptions:["BPRatio","Per Month Leave","Leave carry forward","Leave excluding Weekend(YES/NO)","Leave excluding Holidays(YES/NO)"],
    editflag: false,

    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    spinner: false,
    gen: false,
    uid: "",

    configgens: [],

    genedit: false,
    types: [],
    configtype: "",
    YesNo: ["YES", "NO"],
    configyesnotype: false,
    confignumbertype: false,
    roles: "",
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Config" : "Edit Config"
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile

    console.log("config crated", this.roles)
    if (this.roles) {
      this.editflag = true
      this.domain = this.roles.domain
      this.gen = true
      if (this.roles.userconfig) this.configgens = this.roles.userconfig
      else this.configgens = []
      this.uid = this.roles.uid
      this.initialize()
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },
  methods: {
    descriptionslected() {
      this.configyesnotype = false
      this.confignumbertype = false

      console.log("inside edited item gen", this.editedItem.description)
      var filteredItems = this.configall.filter((config) => {
        return config.description == this.editedItem.description
      })
      this.editedItem.comments = filteredItems[0].comments
      this.editedItem.type = filteredItems[0].type

      console.log("atter filter", filteredItems, this.editedItem.comments, this.editedItem.type)
      if (this.editedItem.type == "number") this.confignumbertype = true
      else if (this.editedItem.type == "YES/NO") this.configyesnotype = true
    },
    async initialize() {
      var queryresult = await configallRef.where("user", "==", "user").get()
      var tmpconfiggens = this.configgens
      for (var i = 0; i < queryresult.docs.length; ++i) {
        var description = queryresult.docs[i].data().description
        var a = (tmpconfiggens) => tmpconfiggens.description == description
        var valuefound = tmpconfiggens.some(a)
        if (!valuefound) {
          this.descriptions.push(queryresult.docs[i].data().description)
          this.comments.push(queryresult.docs[i].data().comments)
          this.types.push(queryresult.docs[i].data().type)
        }
        this.configall.push({
          description: queryresult.docs[i].data().description,
          comments: queryresult.docs[i].data().comments,
          type: queryresult.docs[i].data().type,
        })
      }

      console.log("Config all", this.configall)

      this.headers = this.headersgen
      this.configs = this.configgens
      this.gen = true
      this.notloaded = false
      this.editedItem = { description: "", value: "", domain: "", comments: "", type: "" }
      this.defaultItem = { description: "", value: "", domain: "", comments: "", type: "" }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    editItem(item) {
      if (this.gen) {
        this.editedIndex = this.configgens.indexOf(item)
        this.genedit = true
        this.configtype = ""
      }

      this.editedItem = Object.assign({}, item)

      if (this.gen) {
        this.descriptionslected()
      }

      this.dialog = true
    },

    close() {
      this.dialog = false
      this.genedit = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedIndex > -1) {
        console.log("inside edit item", this.editedItem)
        console.log("edititeditem", this.configs[this.editedIndex])
        this.configs[this.editedIndex].description = this.editedItem.description
        this.configs[this.editedIndex].value = this.editedItem.value
      } else {
        console.log("outside edit item", this.editedItem)
        this.configs.push({
          description: this.editedItem.description,
          value: this.editedItem.value,
        })
      }
      try {
        await usersRef.doc(this.uid).set(
          {
            userconfig: this.configs,
          },
          { merge: true }
        )
        this.snackbar = true
        this.snackbartext = "configuration updated successfully"
        this.spinner = false
      } catch (err) {
        console.log("Error in add ", err)
        this.alertflag = true
        this.alertmsg = "Error while update configuration" + err
        this.spinner = false
      }
      this.close()
    },
  },
}
</script>
