<template>
  <div class="text-center">
    <v-progress-circular :rotate="90" :size="70" :width="7" :value="value" color="purple"></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "Spinner",
  data() {
    return {
      interval: {},
      value: 0,
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0)
      }
      this.value += 10
    }, 1000)
  },
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
